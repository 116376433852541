import React from "react";

const inputCancel = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2169 6.49023C12.4122 6.29497 12.4122 5.97838 12.2169 5.78312C12.0217 5.58786 11.7051 5.58786 11.5098 5.78312L12.2169 6.49023ZM5.78312 11.5098C5.58786 11.7051 5.58786 12.0217 5.78312 12.2169C5.97838 12.4122 6.29497 12.4122 6.49023 12.2169L5.78312 11.5098ZM11.5098 12.2169C11.7051 12.4122 12.0217 12.4122 12.2169 12.2169C12.4122 12.0217 12.4122 11.7051 12.2169 11.5098L11.5098 12.2169ZM6.49023 5.78312C6.29497 5.58786 5.97838 5.58786 5.78312 5.78312C5.58786 5.97838 5.58786 6.29497 5.78312 6.49023L6.49023 5.78312ZM16.6 9.00002C16.6 13.1974 13.1974 16.6 9.00002 16.6V17.6C13.7497 17.6 17.6 13.7497 17.6 9.00002H16.6ZM9.00002 16.6C4.80266 16.6 1.40002 13.1974 1.40002 9.00002H0.400024C0.400024 13.7497 4.25038 17.6 9.00002 17.6V16.6ZM1.40002 9.00002C1.40002 4.80266 4.80266 1.40002 9.00002 1.40002V0.400024C4.25038 0.400024 0.400024 4.25038 0.400024 9.00002H1.40002ZM9.00002 1.40002C13.1974 1.40002 16.6 4.80266 16.6 9.00002H17.6C17.6 4.25038 13.7497 0.400024 9.00002 0.400024V1.40002ZM11.5098 5.78312L5.78312 11.5098L6.49023 12.2169L12.2169 6.49023L11.5098 5.78312ZM12.2169 11.5098L6.49023 5.78312L5.78312 6.49023L11.5098 12.2169L12.2169 11.5098Z"
        fill="#918DAB"
      />
    </svg>
  );
};

export default inputCancel;
