import React from "react";

export const Call2actIcon = () => {
  return (
    <svg width="1304" height="407" viewBox="0 0 1304 407" fill="none">
      <mask
        id="mask0"
        masktype="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1279"
        height="382"
      >
        <rect width="1279" height="382" rx="20" fill="#ffffff" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M1235.5 299.216C1235.5 431.613 1129.61 538.931 999.002 538.931C868.392 538.931 762.5 431.613 762.5 299.216C762.5 166.818 868.392 59.5 999.002 59.5C1129.61 59.5 1235.5 166.818 1235.5 299.216Z"
          fill="#232332"
          stroke="#EEFFE2"
        />
        <ellipse
          cx="1032.01"
          cy="345.661"
          rx="237.002"
          ry="240.216"
          fill="#232332"
        />
        <path
          d="M1268.52 345.661C1268.52 478.058 1162.62 585.376 1032.01 585.376C901.404 585.376 795.513 478.058 795.513 345.661C795.513 213.263 901.404 105.945 1032.01 105.945C1162.62 105.945 1268.52 213.263 1268.52 345.661Z"
          stroke="white"
          strokeOpacity="0.25"
        />
        <path
          d="M1303.5 395.602C1303.5 527.999 1197.61 635.317 1067 635.317C936.388 635.317 830.496 527.999 830.496 395.602C830.496 263.204 936.388 155.886 1067 155.886C1197.61 155.886 1303.5 263.204 1303.5 395.602Z"
          fill="#232332"
          stroke="#EEFFE2"
        />
      </g>
    </svg>
  );
};

export default Call2actIcon;
