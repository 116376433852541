import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./containers/Home"
import { Terms } from "./containers/Terms";
import { Privacy } from "./containers/Privacy";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
          <Route path="/terms" exact component={ Terms } />
          <Route path="/privacy-policy" exact component={ Privacy } />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
