import React from "react";

export const Elevator = () => {
  return (
    <svg width="491" height="491" viewBox="0 0 491 491" fill="none">
      <path
        d="M480 0H10.667C4.779 0 0 4.779 0 10.667V480C0 485.888 4.779 490.667 10.667 490.667H96C101.888 490.667 106.667 485.888 106.667 480V469.333H384V480C384 485.888 388.779 490.667 394.667 490.667H480C485.888 490.667 490.667 485.888 490.667 480V10.667C490.667 4.779 485.888 0 480 0ZM469.333 469.333H405.333V458.666C405.333 452.778 400.554 447.999 394.666 447.999H96C90.112 447.999 85.333 452.778 85.333 458.666V469.333H21.333V21.333H469.333V469.333V469.333Z"
        fill="white"
      />
      <path
        d="M394.667 106.667H96C90.112 106.667 85.333 111.446 85.333 117.334V458.667C85.333 464.555 90.112 469.334 96 469.334H394.667C400.555 469.334 405.334 464.555 405.334 458.667V117.333C405.333 111.445 400.555 106.667 394.667 106.667ZM384 448H106.667V128H384V448Z"
        fill="white"
      />
      <path
        d="M245.333 106.667C239.445 106.667 234.666 111.446 234.666 117.334V458.667C234.666 464.555 239.445 469.334 245.333 469.334C251.221 469.334 256 464.555 256 458.667V117.333C256 111.445 251.221 106.667 245.333 106.667Z"
        fill="white"
      />
      <path
        d="M437.333 234.667C443.224 234.667 448 229.891 448 224C448 218.109 443.224 213.333 437.333 213.333C431.442 213.333 426.666 218.109 426.666 224C426.666 229.891 431.442 234.667 437.333 234.667Z"
        fill="white"
      />
      <path
        d="M437.333 277.334C443.224 277.334 448 272.558 448 266.667C448 260.776 443.224 256 437.333 256C431.442 256 426.666 260.776 426.666 266.667C426.666 272.558 431.442 277.334 437.333 277.334Z"
        fill="white"
      />
      <path
        d="M210.219 67.136L188.886 45.803C184.726 41.643 177.963 41.643 173.803 45.803L152.47 67.136C148.31 71.296 148.31 78.059 152.47 82.219C156.63 86.379 163.393 86.379 167.553 82.219L181.334 68.416L195.115 82.219C197.206 84.288 199.936 85.334 202.667 85.334C205.398 85.334 208.128 84.289 210.219 82.219C214.379 78.059 214.379 71.296 210.219 67.136Z"
        fill="white"
      />
      <path
        d="M338.197 45.803C334.037 41.643 327.274 41.643 323.114 45.803L309.333 59.584L295.53 45.803C291.37 41.643 284.607 41.643 280.447 45.803C276.287 49.963 276.287 56.726 280.447 60.886L301.78 82.219C303.871 84.288 306.601 85.334 309.332 85.334C312.063 85.334 314.793 84.289 316.863 82.219L338.196 60.886C342.357 56.725 342.357 49.963 338.197 45.803Z"
        fill="white"
      />
    </svg>
  );
};

export default Elevator;
