import React from "react";

export const Refrigeration = () => {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none">
      <path
        d="M418.85 72.588C423.474 72.588 427.223 68.8393 427.223 64.215C427.223 59.5908 423.474 55.842 418.85 55.842C414.226 55.842 410.477 59.5908 410.477 64.215C410.477 68.8393 414.226 72.588 418.85 72.588Z"
        fill="white"
      />
      <path
        d="M431.845 29.1491C427.927 27.1591 423.521 26.1071 419.102 26.1071C414.415 26.1071 409.77 27.2841 405.672 29.5111C402.043 31.4821 400.699 36.0211 402.67 39.6501C404.025 42.1451 406.594 43.5601 409.247 43.5601C410.452 43.5601 411.675 43.2671 412.809 42.6521C414.752 41.5971 416.869 41.0621 419.102 41.0621C421.208 41.0621 423.216 41.5401 425.072 42.4821C428.754 44.3541 433.255 42.8841 435.124 39.2021C436.996 35.5201 435.527 31.0191 431.845 29.1491V29.1491Z"
        fill="white"
      />
      <path
        d="M453.455 9.539C443.15 3.298 431.305 0 419.199 0C407.096 0 395.251 3.298 384.946 9.538C381.414 11.677 380.284 16.274 382.423 19.807C384.561 23.339 389.158 24.468 392.692 22.33C400.661 17.504 409.827 14.954 419.2 14.954C428.574 14.954 437.741 17.505 445.709 22.331C446.92 23.064 448.256 23.413 449.575 23.413C452.104 23.413 454.572 22.129 455.978 19.808C458.116 16.276 456.987 11.679 453.455 9.539V9.539Z"
        fill="white"
      />
      <path
        d="M261.874 289.215H261.922H325.758C329.628 289.199 333.261 287.677 335.988 284.93C338.714 282.182 340.207 278.537 340.191 274.696V46.7739C340.159 38.9369 333.114 32.3409 324.82 32.3409C324.8 32.3409 324.78 32.3409 324.759 32.3409H260.907C253.165 32.3719 247.352 38.7369 247.386 47.1159V275.038C247.402 278.966 248.973 282.602 251.81 285.275C254.512 287.817 258.083 289.215 261.874 289.215V289.215ZM262.341 47.2949H324.778C324.894 47.3019 325.084 47.3829 325.238 47.4779V274.262H262.341V47.2949Z"
        fill="white"
      />
      <path
        d="M132.866 385.982L289.545 385.355C293.674 385.338 297.008 381.978 296.992 377.848C296.975 373.729 293.631 370.401 289.515 370.401C289.505 370.401 289.495 370.401 289.484 370.401L132.806 371.028C128.676 371.045 125.342 374.406 125.359 378.535C125.376 382.655 128.72 385.982 132.836 385.982H132.866Z"
        fill="white"
      />
      <path
        d="M353.164 0.570068H69.2589C61.3629 0.570068 54.9399 6.99407 54.9399 14.8901V402.55C54.9399 406.679 58.2879 410.027 62.4169 410.027C66.5459 410.027 69.8939 406.679 69.8939 402.55V352.204H352.529V467.167H69.8939V437.608C69.8939 433.479 66.5459 430.131 62.4169 430.131C58.2879 430.131 54.9399 433.479 54.9399 437.608L55.0349 496.444C55.0699 505.036 62.0699 512 70.6529 512H70.7169L95.5239 511.901C101.863 511.876 107.492 508.086 109.903 502.232L118.736 482.122H303.719L312.758 502.395C315.204 508.215 320.835 511.959 327.153 511.959H327.216L352.024 511.86C360.583 511.825 367.518 504.791 367.483 496.209V14.8901C367.483 6.99407 361.06 0.570068 353.164 0.570068V0.570068ZM96.1699 496.31C96.1379 496.383 96.1069 496.456 96.0769 496.53C95.9759 496.782 95.7349 496.946 95.4629 496.947L70.6549 497.046C70.5039 497.032 70.3149 496.982 70.1849 496.853C70.0559 496.725 69.9889 496.567 69.9889 496.415V482.122H102.401L96.1699 496.31ZM352.529 496.239C352.53 496.559 352.354 496.904 351.964 496.905L327.156 497.004H327.154C326.883 497.004 326.643 496.843 326.54 496.593C326.512 496.525 326.483 496.457 326.453 496.39L320.091 482.122H352.529V496.239ZM202.328 15.5251H220.096V27.2701H202.328V15.5251ZM202.328 42.2241H220.096V278.881H202.328V42.2241ZM202.328 293.836H220.096V305.438H202.328V293.836ZM69.8939 15.5251H187.373V305.438H69.8939V15.5251ZM352.529 305.438H322.129C318 305.438 314.652 308.786 314.652 312.915C314.652 317.044 318 320.392 322.129 320.392H352.529V337.251H69.8939V320.392H286.406C290.535 320.392 293.883 317.044 293.883 312.915C293.883 308.786 290.535 305.438 286.406 305.438H235.05V286.378C235.05 286.371 235.051 286.365 235.051 286.359V34.7471C235.051 34.7401 235.05 34.7341 235.05 34.7281V15.5251H352.529V305.438Z"
        fill="white"
      />
      <path
        d="M158.584 94.684H158.581H97.579H97.576C90.269 94.684 84.325 100.629 84.325 107.935V107.938V213.617C84.325 220.926 90.271 226.872 97.58 226.872H158.581C165.89 226.872 171.836 220.926 171.836 213.617V107.939V107.936C171.835 100.629 165.891 94.684 158.584 94.684V94.684ZM156.881 211.918H99.279V151.084H112.316C116.764 151.084 120.694 148.411 122.328 144.273L124.75 138.138H131.411L133.832 144.271C135.465 148.41 139.395 151.084 143.844 151.084H156.881V211.918ZM156.881 136.13H146.694L144.273 129.996C142.64 125.858 138.71 123.184 134.261 123.184H121.898C117.448 123.184 113.517 125.859 111.886 129.996L109.465 136.13H99.279V109.638H156.882V136.13H156.881Z"
        fill="white"
      />
    </svg>
  );
};

export default Refrigeration;
