import React from "react";

export const MovingHelp = () => {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none">
      <path
        d="M328 216C323.576 216 320 219.576 320 224V360C320 364.424 323.576 368 328 368C332.424 368 336 364.424 336 360V224C336 219.576 332.424 216 328 216Z"
        fill="black"
      />
      <path
        d="M504 416H480.168C475.744 416 472.168 419.576 472.168 424C472.168 428.424 475.744 432 480.168 432H504C508.424 432 512 428.424 512 424C512 419.576 508.424 416 504 416Z"
        fill="black"
      />
      <path
        d="M504 416C499.576 416 496 419.576 496 424V456C496 460.424 499.576 464 504 464C508.424 464 512 460.424 512 456V424C512 419.576 508.424 416 504 416Z"
        fill="black"
      />
      <path
        d="M80.0001 448C79.9921 448 79.9841 448 79.9761 448L23.9761 448.168C19.5521 448.184 15.9841 451.768 16.0001 456.192C16.0161 460.608 19.5921 464.168 24.0001 464.168C24.0081 464.168 24.0161 464.168 24.0241 464.168L80.0241 464C84.4481 463.984 88.0161 460.4 88.0001 455.976C87.9841 451.56 84.4081 448 80.0001 448Z"
        fill="black"
      />
      <path
        d="M496 376H488C483.576 376 480 379.576 480 384C480 388.424 483.576 392 488 392H496C500.424 392 504 388.424 504 384C504 379.576 500.424 376 496 376Z"
        fill="black"
      />
      <path
        d="M496 320C491.576 320 488 323.576 488 328V424C488 428.424 491.576 432 496 432C500.424 432 504 428.424 504 424V328C504 323.576 500.424 320 496 320Z"
        fill="black"
      />
      <path
        d="M448 216H328C323.576 216 320 219.576 320 224C320 228.424 323.576 232 328 232H448C452.424 232 456 228.424 456 224C456 219.576 452.424 216 448 216Z"
        fill="black"
      />
      <path
        d="M503.264 324.648L455.264 220.648C453.416 216.632 448.672 214.888 444.648 216.736C440.632 218.584 438.888 223.344 440.736 227.352L488.736 331.352C490.088 334.28 492.984 336 496.008 336C497.128 336 498.264 335.768 499.352 335.264C503.368 333.416 505.112 328.656 503.264 324.648Z"
        fill="black"
      />
      <path
        d="M24 416C19.576 416 16 419.576 16 424V456.504C16 460.928 19.576 464.504 24 464.504C28.424 464.504 32 460.92 32 456.504V424C32 419.576 28.424 416 24 416Z"
        fill="black"
      />
      <path
        d="M8 312C3.576 312 0 315.576 0 320V424C0 428.424 3.576 432 8 432C12.424 432 16 428.424 16 424V320C16 315.576 12.424 312 8 312Z"
        fill="black"
      />
      <path
        d="M328 312H8C3.576 312 0 315.576 0 320C0 324.424 3.576 328 8 328H328C332.424 328 336 324.424 336 320C336 315.576 332.424 312 328 312Z"
        fill="black"
      />
      <path
        d="M48 416H8C3.576 416 0 419.576 0 424C0 428.424 3.576 432 8 432H48C52.424 432 56 428.424 56 424C56 419.576 52.424 416 48 416Z"
        fill="black"
      />
      <path
        d="M128 400C97.128 400 72 425.128 72 456C72 486.872 97.128 512 128 512C158.872 512 184 486.872 184 456C184 425.128 158.872 400 128 400ZM128 496C105.944 496 88 478.056 88 456C88 433.944 105.944 416 128 416C150.056 416 168 433.944 168 456C168 478.056 150.056 496 128 496Z"
        fill="black"
      />
      <path
        d="M128 432C114.768 432 104 442.768 104 456C104 469.232 114.768 480 128 480C141.232 480 152 469.232 152 456C152 442.768 141.232 432 128 432ZM128 464C123.584 464 120 460.416 120 456C120 451.584 123.584 448 128 448C132.416 448 136 451.584 136 456C136 460.416 132.416 464 128 464Z"
        fill="black"
      />
      <path
        d="M392 432C378.768 432 368 442.768 368 456C368 469.232 378.768 480 392 480C405.232 480 416 469.232 416 456C416 442.768 405.232 432 392 432ZM392 464C387.584 464 384 460.416 384 456C384 451.584 387.584 448 392 448C396.416 448 400 451.584 400 456C400 460.416 396.416 464 392 464Z"
        fill="black"
      />
      <path
        d="M392 400C361.128 400 336 425.128 336 456C336 486.872 361.128 512 392 512C422.872 512 448 486.872 448 456C448 425.128 422.872 400 392 400ZM392 496C369.944 496 352 478.056 352 456C352 433.944 369.944 416 392 416C414.056 416 432 433.944 432 456C432 478.056 414.056 496 392 496Z"
        fill="black"
      />
      <path
        d="M344.2 448H176C171.576 448 168 451.576 168 456C168 460.424 171.576 464 176 464H344.2C348.624 464 352.2 460.424 352.2 456C352.2 451.576 348.624 448 344.2 448Z"
        fill="black"
      />
      <path
        d="M504 448H440C435.576 448 432 451.576 432 456C432 460.424 435.576 464 440 464H504C508.424 464 512 460.424 512 456C512 451.576 508.424 448 504 448Z"
        fill="black"
      />
      <path
        d="M496 320H400C395.576 320 392 323.576 392 328C392 332.424 395.576 336 400 336H496C500.424 336 504 332.424 504 328C504 323.576 500.424 320 496 320Z"
        fill="black"
      />
      <path
        d="M400 320C386.768 320 376 309.232 376 296C376 291.576 372.424 288 368 288C363.576 288 360 291.576 360 296C360 318.056 377.944 336 400 336C404.424 336 408 332.424 408 328C408 323.576 404.424 320 400 320Z"
        fill="black"
      />
      <path
        d="M368 216C363.576 216 360 219.576 360 224V296C360 300.424 363.576 304 368 304C372.424 304 376 300.424 376 296V224C376 219.576 372.424 216 368 216Z"
        fill="black"
      />
      <path
        d="M352 120C347.576 120 344 123.576 344 128V160C344 164.424 347.576 168 352 168C356.424 168 360 164.424 360 160V128C360 123.576 356.424 120 352 120Z"
        fill="black"
      />
      <path
        d="M40 134.856C35.576 134.856 32 138.432 32 142.856V320C32 324.424 35.576 328 40 328C44.424 328 48 324.424 48 320V142.856C48 138.432 44.424 134.856 40 134.856Z"
        fill="black"
      />
      <path
        d="M328 134.856C323.576 134.856 320 138.432 320 142.856V320C320 324.424 323.576 328 328 328C332.424 328 336 324.424 336 320V142.856C336 138.432 332.424 134.856 328 134.856Z"
        fill="black"
      />
      <path
        d="M356.64 121.488L188.64 1.488C185.856 -0.496 182.128 -0.496 179.344 1.488L11.344 121.488C7.75203 124.056 6.91203 129.056 9.48803 132.648C12.064 136.24 17.064 137.08 20.648 134.504L184 17.832L347.352 134.504C348.76 135.512 350.384 136 351.992 136C354.496 136 356.944 134.832 358.496 132.648C361.072 129.048 360.232 124.048 356.64 121.488Z"
        fill="black"
      />
      <path
        d="M356.64 153.488L188.64 33.488C185.856 31.504 182.128 31.504 179.344 33.488L11.344 153.488C7.75203 156.056 6.91203 161.056 9.48803 164.648C12.064 168.24 17.064 169.072 20.648 166.504L184 49.832L347.352 166.504C348.76 167.512 350.384 168 351.992 168C354.496 168 356.944 166.832 358.496 164.648C361.072 161.048 360.232 156.048 356.64 153.488Z"
        fill="black"
      />
      <path
        d="M16 120C11.576 120 8 123.576 8 128V160C8 164.424 11.576 168 16 168C20.424 168 24 164.424 24 160V128C24 123.576 20.424 120 16 120Z"
        fill="black"
      />
      <path
        d="M208 216C203.576 216 200 219.576 200 224V320C200 324.424 203.576 328 208 328C212.424 328 216 324.424 216 320V224C216 219.576 212.424 216 208 216Z"
        fill="black"
      />
      <path
        d="M208 216H160C155.576 216 152 219.576 152 224C152 228.424 155.576 232 160 232H208C212.424 232 216 228.424 216 224C216 219.576 212.424 216 208 216Z"
        fill="black"
      />
      <path
        d="M160 216C155.576 216 152 219.576 152 224V320C152 324.424 155.576 328 160 328C164.424 328 168 324.424 168 320V224C168 219.576 164.424 216 160 216Z"
        fill="black"
      />
      <path
        d="M128 176H72C67.576 176 64 179.576 64 184V240C64 244.424 67.576 248 72 248H128C132.424 248 136 244.424 136 240V184C136 179.576 132.424 176 128 176ZM120 232H80V192H120V232Z"
        fill="black"
      />
      <path
        d="M296 176H240C235.576 176 232 179.576 232 184V240C232 244.424 235.576 248 240 248H296C300.424 248 304 244.424 304 240V184C304 179.576 300.424 176 296 176ZM288 232H248V192H288V232Z"
        fill="black"
      />
      <path
        d="M184 104C166.352 104 152 118.352 152 136C152 153.648 166.352 168 184 168C201.648 168 216 153.648 216 136C216 118.352 201.648 104 184 104ZM184 152C175.176 152 168 144.824 168 136C168 127.176 175.176 120 184 120C192.824 120 200 127.176 200 136C200 144.824 192.824 152 184 152Z"
        fill="black"
      />
    </svg>
  );
};

export default MovingHelp;
