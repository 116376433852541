import React from "react";

export const Errands = () => {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none">
      <path d="M373.28 471.577H283.463V511.496H373.28V471.577Z" fill="white" />
      <path
        d="M471.083 430.621V450.58C471.083 462.16 461.662 471.58 450.083 471.58H430.124V511.499H450.083C483.675 511.499 511.002 484.171 511.002 450.58V430.621H471.083Z"
        fill="white"
      />
      <path
        d="M227.626 0.49707H137.809V40.4161H227.626V0.49707Z"
        fill="white"
      />
      <path d="M373.28 0.49707H283.463V40.4161H373.28V0.49707Z" fill="white" />
      <path
        d="M60.962 0.49707C27.37 0.49707 0.0429993 27.8251 0.0429993 61.4161V81.3751H39.962V61.4161C39.962 49.8361 49.383 40.4161 60.962 40.4161H80.921V0.49707H60.962Z"
        fill="white"
      />
      <path
        d="M450.083 0.49707H430.124V40.4161H450.083C461.663 40.4161 471.083 49.8371 471.083 61.4161V81.3751H511.002V61.4161C511.002 27.8251 483.675 0.49707 450.083 0.49707Z"
        fill="white"
      />
      <path d="M39.919 143.246H0V233.063H39.919V143.246Z" fill="white" />
      <path
        d="M511.997 288.909H472.078V378.726H511.997V288.909Z"
        fill="white"
      />
      <path
        d="M511.997 143.246H472.078V233.063H511.997V143.246Z"
        fill="white"
      />
      <path
        d="M264.504 103.332V143.25H342.485L210.862 280.771C201.774 275.203 191.094 271.988 179.677 271.988H59.921C26.904 271.988 0.0429993 298.849 0.0429993 331.866V451.622C0.0429993 484.639 26.904 511.5 59.921 511.5H179.677C212.694 511.5 239.555 484.639 239.555 451.622V331.866C239.555 324.925 238.354 318.263 236.173 312.058L369.291 172.975V248.036H409.21V103.332H264.504ZM199.636 451.621C199.636 462.627 190.682 471.58 179.677 471.58H59.921C48.915 471.58 39.962 462.626 39.962 451.621V331.865C39.962 320.859 48.916 311.906 59.921 311.906H179.677C190.683 311.906 199.636 320.86 199.636 331.865V451.621V451.621Z"
        fill="white"
      />
    </svg>
  );
};

export default Errands;
