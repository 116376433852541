//@flow
import React from "react";

import Label from "../Label";
import Password from "../../Icons/Password";
import InputSuccess from "../../Icons/InputSucess";
import InputCancel from "../../Icons/InputCancel";

type SelectProps = {
  attributes: Object,
  hasError: boolean,
  focused: boolean,
  label?: string,
  message?: string,
  messageClassName?: string,
  appendItem: any,
  prependItem: any,
  handleAppendItemClick?: Function,
  isPasswordVisible?: boolean
};

const Select = (props: SelectProps) => {
  let appendedItem,
    selectClassName = "select";
  let messageClassName = props.messageClassName
    ? props.messageClassName + " select__message"
    : "select__message";

  props.hasError &&
    props.attributes.value !== "" &&
    !props.focused &&
    (selectClassName += " select--invalid");
  props.focused && (selectClassName += " select--focus");

  switch (props.appendItem) {
    case "password":
      appendedItem = (
        <div className="select__icon" onClick={props.handleAppendItemClick}>
          {props.isPasswordVisible ? <Password type="close" /> : <Password />}
        </div>
      );
      break;
    case "success":
      appendedItem = (
        <div className="select__icon">
          <InputSuccess />
        </div>
      );
      break;
    case "cancel":
      appendedItem = (
        <div className="select__icon" onClick={props.handleAppendItemClick}>
          <InputCancel />
        </div>
      );
      break;
    default:
      props.appendItem &&
        (appendedItem = (
          <div className="select__icon" onClick={props.handleAppendItemClick}>
            {props.appendItem}
          </div>
        ));
  }
  return (
    <div
      className={
        props.attributes.theme
          ? `select__group select__theme--${props.attributes.theme}`
          : "select__group"
      }
    >
      {props.label && (
        <Label>
          {props.label}
          {props.attributes.required && " *"}
        </Label>
      )}
      <div className={selectClassName}>
        {props.prependItem && (
          <div className="select__prepend">{props.prependItem}</div>
        )}
        <select className="select__item" {...props.attributes}>
          {props.attributes.options &&
            props.attributes.options.length !== 0 &&
            props.attributes.options.map(option => (
              <option
                key={option.value + "-" + option.display}
                value={option.value}
              >
                {option.display}
              </option>
            ))}
        </select>
        {props.appendItem && (
          <div className="select__append">{appendedItem}</div>
        )}
      </div>
      {props.message && (
        <small className={messageClassName}>{props.message}</small>
      )}
    </div>
  );
};

Select.defaultProps = {
  attributes: {
    required: false,
    value: "",
    options: [],
    theme: "default",
    onChange: () => null,
    onFocus: () => null,
    onBlur: () => null
  },
  appendedItem: null,
  handleAppendItemClick: () => null
};

export default Select;
