//@flow
import React from "react";
import ModalClose from "../Icons/ModalClose";

type ModalProps = {
  width: string,
  theme: string,
  visible: boolean,
  isCloseable?: boolean,
  onClose?: Function,
  children: any,
};

const Modal = (props: ModalProps) => {
  let modalWidth = `modal-box modal-box--${props.width} modal-theme--${props.theme}`;

  return (
    <div
      className={`modal ${
        props.visible ? "modal--visible" : "modal--invisible"
      }`}
    >
      <div className="modal-backdrop" onClick={props.onClose} />
      <div className={modalWidth}>
        {props.isCloseable ? (
          <div className="modal-close" onClick={props.onClose}>
            <ModalClose />
          </div>
        ) : null}
        {props.children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  visible: false,
  width: "medium",
  theme: "white",
  isCloseable: true,
};

export default Modal;
