import React from "react";

export const Usher = () => {
  return (
    <svg width="370" height="434" viewBox="0 0 370 434" fill="none">
      <path
        d="M369.465 340.482C369.68 341.947 369.271 343.401 368.339 344.537C326.137 395.167 270.202 426.924 210.847 433.949C210.632 433.979 210.438 433.99 210.233 433.99C208.891 433.99 207.601 433.457 206.638 432.495C205.532 431.419 204.979 429.873 205.143 428.327L220.289 284.107C220.074 285.664 219.286 287.087 217.913 287.886C217.135 288.326 216.254 288.562 215.363 288.562C214.473 288.562 213.582 288.326 212.783 287.865L199.583 280.164C191.923 287.886 178.046 287.886 170.397 280.164L157.196 287.865C155.599 288.787 153.653 288.797 152.066 287.865C150.683 287.067 149.895 285.643 149.68 284.087L164.826 428.316C164.99 429.852 164.437 431.409 163.331 432.495C162.368 433.437 161.078 433.979 159.736 433.979C159.531 433.979 159.337 433.969 159.132 433.939C99.7872 426.893 43.8526 395.157 1.65075 344.537C0.708603 343.401 0.298977 341.936 0.524272 340.482C9.96618 278.895 42.1424 229.412 91.1236 201.158C93.1717 199.98 95.7626 200.349 97.3909 202.059C109.506 214.768 124.703 224.885 141.344 231.378C143.136 232.064 144.375 233.723 144.58 235.618L149.495 282.438V248.5C149.495 246.647 150.479 244.967 152.066 244.035C153.653 243.114 155.609 243.134 157.196 244.046L169.874 251.46C177.606 243.103 192.353 243.103 200.084 251.46L212.752 244.046C214.339 243.114 216.306 243.114 217.883 244.035C219.47 244.967 220.453 246.637 220.453 248.5V282.438L225.358 235.618C225.573 233.692 226.813 232.064 228.584 231.378C245.225 224.896 260.422 214.768 272.537 202.059C274.186 200.359 276.767 200 278.804 201.158C327.847 229.412 360.013 278.895 369.465 340.482ZM184.99 209.811C242.952 209.811 289.905 162.847 289.905 104.906C289.905 46.9638 242.952 0 184.99 0C127.038 0 80.0841 46.9638 80.0841 104.906C80.0841 162.847 127.038 209.811 184.99 209.811Z"
        fill="white"
      />
    </svg>
  );
};

export default Usher;
