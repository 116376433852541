import React from "react";

export const AirConditioning = () => {
  return (
    <svg width="360" height="220" viewBox="0 0 360 220" fill="none">
      <path
        d="M334.698 0.805176H85.5966C82.678 0.805176 80.3119 3.17061 80.3119 6.08987C80.3119 9.00914 82.678 11.3746 85.5966 11.3746H334.698C342.822 11.3746 349.431 17.984 349.431 26.1083V138.631C349.431 146.755 342.822 153.365 334.698 153.365H322.043V118.932C322.043 116.012 319.677 113.647 316.758 113.647H43.2415C40.3229 113.647 37.9568 116.012 37.9568 118.932V153.365H25.3031C17.1788 153.365 10.5694 146.755 10.5694 138.631V26.1083C10.5694 17.984 17.1788 11.3746 25.3031 11.3746H64.4578C67.3764 11.3746 69.7425 9.00914 69.7425 6.08987C69.7425 3.17061 67.3764 0.805176 64.4578 0.805176H25.3031C11.3508 0.805176 0 12.156 0 26.1083V138.631C0 152.583 11.3508 163.934 25.3031 163.934H80.314C83.2326 163.934 85.5987 161.569 85.5987 158.649C85.5987 155.731 83.2326 153.365 80.314 153.365H48.5262V144.075H258.548C261.466 144.075 263.833 141.709 263.833 138.79C263.833 135.871 261.466 133.505 258.548 133.505H48.5262V124.216H311.474V133.505H279.687C276.768 133.505 274.402 135.871 274.402 138.79C274.402 141.709 276.768 144.075 279.687 144.075H311.474V153.365H101.452C98.5335 153.365 96.1674 155.731 96.1674 158.649C96.1674 161.569 98.5335 163.934 101.452 163.934H334.698C348.65 163.934 360.001 152.583 360.001 138.631V26.1083C360 12.1567 348.649 0.805176 334.698 0.805176Z"
        fill="black"
      />
      <path
        d="M316.759 43.774C319.677 43.774 322.043 41.4086 322.043 38.4893C322.043 35.57 319.677 33.2046 316.759 33.2046H301.129C298.211 33.2046 295.844 35.57 295.844 38.4893C295.844 41.4086 298.211 43.774 301.129 43.774H316.759Z"
        fill="black"
      />
      <path
        d="M277.016 43.774C279.935 43.774 282.301 41.4086 282.301 38.4893C282.301 35.57 279.935 33.2046 277.016 33.2046H176.99C174.072 33.2046 171.705 35.57 171.705 38.4893C171.705 41.4086 174.072 43.774 176.99 43.774H277.016Z"
        fill="black"
      />
      <path
        d="M99.4101 178.074C96.4916 178.074 94.1254 180.439 94.1254 183.358C94.1254 183.516 93.9556 199.182 79.2951 209.602C76.9163 211.293 76.3583 214.592 78.0494 216.971C79.0802 218.422 80.7079 219.195 82.3617 219.195C83.4207 219.195 84.4904 218.877 85.4191 218.217C104.476 204.671 104.696 184.222 104.696 183.358C104.695 180.439 102.329 178.074 99.4101 178.074Z"
        fill="black"
      />
      <path
        d="M137.249 178.074C134.331 178.074 131.965 180.439 131.965 183.358V213.91C131.965 216.829 134.331 219.194 137.249 219.194C140.168 219.194 142.534 216.829 142.534 213.91V183.358C142.534 180.439 140.168 178.074 137.249 178.074Z"
        fill="black"
      />
      <path
        d="M181.486 178.074C178.568 178.074 176.202 180.439 176.202 183.358V213.91C176.202 216.829 178.568 219.194 181.486 219.194C184.405 219.194 186.771 216.829 186.771 213.91V183.358C186.771 180.439 184.405 178.074 181.486 178.074Z"
        fill="black"
      />
      <path
        d="M280.706 209.602C266.242 199.322 265.883 184.038 265.875 183.334C265.863 180.426 263.501 178.074 260.591 178.074C257.672 178.074 255.306 180.439 255.306 183.358C255.306 184.222 255.525 204.672 274.582 218.218C275.511 218.878 276.581 219.196 277.64 219.196C279.293 219.195 280.921 218.421 281.952 216.972C283.642 214.592 283.084 211.293 280.706 209.602Z"
        fill="black"
      />
      <path
        d="M222.751 178.074C219.832 178.074 217.466 180.439 217.466 183.358V213.91C217.466 216.829 219.832 219.194 222.751 219.194C225.669 219.194 228.035 216.829 228.035 213.91V183.358C228.035 180.439 225.669 178.074 222.751 178.074Z"
        fill="black"
      />
    </svg>
  );
};

export default AirConditioning;
