import React from "react";

export const StarIcon = () => {
  return (
    <svg width="220" height="212" viewBox="0 0 220 212" fill="none">
      <path
        d="M219.425 80.3827C217.976 75.928 214.025 72.7741 209.369 72.3528L145.861 66.5872L120.763 7.82644C118.91 3.50935 114.693 0.723053 110 0.723053C105.307 0.723053 101.089 3.50935 99.2479 7.82644L74.1493 66.5872L10.6317 72.3528C5.97557 72.7825 2.03279 75.9364 0.575857 80.3827C-0.872684 84.8375 0.465076 89.7236 3.98656 92.812L51.9932 134.907L37.8384 197.25C36.8028 201.834 38.582 206.574 42.3855 209.323C44.4299 210.809 46.8318 211.55 49.2421 211.55C51.3134 211.55 53.3863 211 55.2377 209.892L110 177.148L164.753 209.892C168.769 212.292 173.82 212.072 177.615 209.323C181.419 206.574 183.198 201.834 182.162 197.25L168.007 134.907L216.014 92.812C219.534 89.7236 220.873 84.8475 219.425 80.3827Z"
        fill="#8EBE6E"
      />
    </svg>
  );
};

export default StarIcon;
